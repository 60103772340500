import { BaseUser } from './base-user';
import { GlobalConstants } from '@app/utils/Constants';

export class CompanyMember extends BaseUser {

    public permissions: number = null;
    public admin_type_name: string = null;
    public can_sign_off: boolean = false;

    public fromJSON(json: any) {
        super.fromJSON(json);
        this.permissions = json["permissions"] || this.permissions;
        this.admin_type_name = GlobalConstants.ADMIN_TYPES[this.permissions];
        if(!this.admin_type_name) this.admin_type_name = "Member";
        this.can_sign_off = json["can_sign_off"] || this.can_sign_off;
        return this;
    }

    public toJSON() {
        return {
          ...super.toJSON(),
          permissions: this.permissions,
          admin_type_name: this.admin_type_name
        };
    }
}